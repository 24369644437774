@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiTableRow-root:first-child{
    padding: 0px !important;
}

.form-container__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
  }
  
  .loading-spinner__spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  .date-picker {
    font-size: 14px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .swal2-select {
    width: 57%;
    margin-top: 5px;
    border: solid 1px #d9d9d9;
  }